import React, { Fragment } from "react";
import { adminMailId, colors } from "../utils/constants";
import logoImg from "../assets/logo1.png";
import {
  // FaFacebook,
  // FaInstagramSquare,
  FaLinkedinIn,
  FaMailBulk,
  // FaTwitterSquare,
  // FaYoutube,
} from "react-icons/fa";
import { BsSkype } from "react-icons/bs";

const Footer = () => {
  const industries = [
    { title: "Messaging" },
    { title: "Chatbots" },
    { title: "Agri-Tech" },
    { title: "E-Com" },
    { title: "Logistics" },
    { title: "Marketplace" },
    {
      title: "Entertainment",
    },
    { title: "ERP/CRM Software" },
    { title: "Fin-tech" },
    {
      title: "Project Management Tool",
    },
    { title: "Healthcare" },
    { title: "Travel" },
    {
      title: "Ed-Tech",
    },
  ];

  const technologies = [
    "React.js",
    "React Native",
    "Node.js",
    "Type Script",
    "PHP",
    "MongoDB",
    "Wordpress",
    "HTML5",
    "Next.js",
    "MERN",
    "MEAN",
    "Manual/Automatation QA",
    "DevOps",
  ];

  const handleSkypeChatClick = () => {
    const skypeUsername = "live:.cid.47d64632b9b94796"; // vidit.viionn (viionn labs skype user name)

    // Create the skype link
    const skypeLink = `skype:${skypeUsername}?chat`;

    // Open the Skype chatbox
    window.location.href = skypeLink;
  };

  const handlelinkedin = () => {
    window.open("https://www.linkedin.com/in/viditkshukla", "_blank");
  };
   const handleEmailClick = () => {
      var mailtoLink =
        "mailto:" +
        adminMailId +
        "?subject=" +
        encodeURIComponent(`Mail from Viionn website`) +
        "&body=" +
        encodeURIComponent('Message:- ');
  
      window.location.href = mailtoLink;
    };
  

  return (
    <Fragment>
      <div
        className="content-wrap"
        style={{ backgroundColor: colors.lightBlack, color: "white" }}
      >
        <div className="p-5 pt-0">
          <div>
            <img src={logoImg} style={{ width: "130px" }} alt="img"/>
          </div>
          <div className="row mt-3">
            <div
              className="col-md-4"
              style={{ borderRight: `1px solid #afafaf29` }}
            >
              <div style={{ color: colors.mainColor }}>ABOUT US</div>
              <div className="mt-3">
                Founded in 2017, Viionn is a leading IT Staff Augmentation
                Company that empowers novel organizations towards high paced
                growth through accomplished developers. We strive to solve
                development team management issues for startups and help them
                focus on product growth using latest disruptive technology.
              </div>
              <div className="mt-5" style={{ color: colors.mainColor }}>
                INDUSTRIES
              </div>
              <div className="row">
                {industries.map((tech, i) => {
                  return (
                    <div className="col-md-4 mt-4 d-flex aic" key={i}>
                      {tech.title}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="col-md-4"
              style={{ borderRight: `1px solid #afafaf29` }}
            >
              <div className="d-flex">
                <div>
                  <div
                    style={{ color: colors.mainColor, marginBottom: "30px" }}
                  >
                    MODELS
                  </div>
                  <div className="mb-3">Dedicated Developer Model</div>
                  <div className="mb-3 pt-3">Offshore Development Center</div>
                </div>
                <div>
                  <div style={{ color: colors.mainColor, paddingLeft: "5px" }}>
                    HIRE DEDICATED REMOTE DEVELOPERS IN INDIA
                  </div>
                  <div className="Hire_Dedicated">
                    {technologies.map((tech, i) => {
                      return (
                        <div key={i} className="mt-4">
                          {tech}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div style={{ color: colors.mainColor, marginBottom: "20px" }}>
                CONTACT US
              </div>
              <div className="d-flex ">
                <div
                  className="d-flex aic jcsb border-left-main-color w-100"
                  style={{
                    paddingLeft: "15px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                  onClick={handleEmailClick}
                >
                  <div>FOR ANY ENQUIRY</div>
                  <div>{adminMailId}</div>
                </div>
              </div>
              <div
                className="mt-5"
                style={{ color: colors.mainColor, marginBottom: "20px" }}
              >
                GET CONNECTED
              </div>
              <div className="d-flex flex-wrap aic mt-3">
                <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                  onClick={handleSkypeChatClick}
                >
                  <BsSkype style={{ fontSize: "25px", color: "white" }} />
                </div>
                <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                  onClick={handlelinkedin}
                >
                  <FaLinkedinIn style={{ fontSize: "25px", color: "white" }} />
                </div>
              <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                  onClick={handleEmailClick}
                >
                  <FaMailBulk style={{ fontSize: "25px", color: "white" }} />
                </div>
                {/*   <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                >
                  <FaInstagramSquare
                    style={{ fontSize: "25px", color: "white" }}
                  />
                </div>
                <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                >
                  <FaTwitterSquare
                    style={{ fontSize: "25px", color: "white" }}
                  />
                </div>
                <div
                  className="social-icons"
                  style={{ backgroundColor: "gray" }}
                >
                  <FaYoutube style={{ fontSize: "25px", color: "white" }} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
