import React from "react";
import grab from "../../assets/comapniessLogo/Grab.png";
import spit from "../../assets/comapniessLogo/Spit.png";
import insta from "../../assets/comapniessLogo/insta.png";
import CloudFrame from "../../assets/comapniessLogo/CloudFrame.webp";
import Cymetrix from "../../assets/comapniessLogo/Cymetrix.png";
import Aaban from "../../assets/comapniessLogo/Aaban.png";
import Brick from "../../assets/comapniessLogo/BrickLogo.svg";
import CallUp from "../../assets/comapniessLogo/CallUp.webp";
import Togl from "../../assets/comapniessLogo/togl.png";
import CloudDuty from "../../assets/comapniessLogo/CloudDuty.webp";
import Hvantage from "../../assets/comapniessLogo/Hvantage.png";
import Dynapt from "../../assets/comapniessLogo/Dynapt.png";
import itinformatix from "../../assets/comapniessLogo/itinformatix.png";
import nomupay from "../../assets/comapniessLogo/nomupay.png";
import Biteu from "../../assets/comapniessLogo/biteu.png";
import edvanza from "../../assets/comapniessLogo/edvanza.jpg";
import Zoom from "react-reveal/Zoom";
import LightSpeed from 'react-reveal/LightSpeed';

const CompaniesAchieved = () => {
  return (
    <div style={{ padding: "0 30px" }}>
      <div className="row">
        <div className="col-md-5">
          <LightSpeed left>
            <div className="left-companies-section">
              <div className="companies-heading">
                Companies have achieved 5x faster growth with Remote Tech Teams.
              </div>
              <div className="mt-4">
                When borders disappear the benefits of global ecosystem is used
                effectively, enabling companies to grow rounds the clock.
              </div>
            </div>
          </LightSpeed>
        </div>
        <div className="col-md-7 hidden-in-responsive">
          <Zoom left>
            <div
              style={{
                padding: "15px",
                marginRight: "120px",
                marginTop: "-30px",
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div className="com-grid-1-block col-md-2">
                  <img className="cloudframe" src={CloudFrame} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img className="Cymetrix" src={Cymetrix} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img className="Aaban" src={Aaban} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img className="brick" src={Brick} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img className="callup" src={CallUp} alt="img"/>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div className="col-md-2">
                  <div className="com-grid-1-block">
                    <img className="togl" src={Togl} alt="img"/>
                  </div>
                  <div className="com-grid-1-block">
                    <img className="cloud" src={CloudDuty} alt="img"/>
                  </div>
                  <div className="com-grid-1-block">
                    <img className="Hvantage" src={Hvantage} alt="img"/>
                  </div>
                </div>
                <div className="col-md-7 mt-1 mb-3">
                  <div
                    className="com-grid-middle-block"
                    style={{
                      height: "100%",
                      backgroundColor: "#2F2F2F",
                      borderRadius: "20px",
                      textAlign: "center",
                      flexDirection: "column",
                      color: "white",
                      padding: "0 25px",
                    }}
                  >
                    <div className="clients-no">50+</div>
                    <div className="cl-lettr">Clients</div>
                    <div className="cl-descr">
                      Across FinTech, EdTech, SaaS, HyperLocal, MarketPlace,
                      Chat Domain among others.,
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="com-grid-1-block">
                    <img className="dynapt" src={Dynapt} alt="img"/>
                  </div>
                  <div className="com-grid-1-block">
                    <img className="itinfo" src={itinformatix} alt="img"/>
                  </div>
                  <div className="com-grid-1-block">
                    <img className="nomupay" src={nomupay} alt="img"/>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div className="com-grid-1-block col-md-2">
                  <img className="biteu" src={Biteu} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img className="edvanza" src={edvanza} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img src={grab} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img src={spit} alt="img"/>
                </div>
                <div className="com-grid-1-block col-md-2">
                  <img src={insta} alt="img"/>
                </div>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default CompaniesAchieved;
